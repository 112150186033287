import React from "react";
import "bootstrap/dist/css/bootstrap.css";
// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Components/HomeComponent/Home";
import Header from "./Components/HeaderComponent/Header";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header"> 
          <Header />
        </header>
        <Switch>
          {/* <Route path="/about">
            <About />
          </Route>
          <Route path="/users">
            <Users />
          </Route> */}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
