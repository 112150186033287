import React from "react";
import slide5 from "../../exopics/Slide5.JPG";
import "./Home.css";
class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <h5 className="pynchExplanation">
            Pynch is an Electronic automatic coin dispenser designed to help
            rehabilitate communities by helping its merchant
          </h5>
          <img alt="slide5" id="slide5" src={slide5} />
          <h4 className="pynchHardware">
            Pynch is a fee-free hardware solution for merchants that helps do
            away with transaction fees entirely. Across America transaction fees
            represent billions of dollars being taken from communities. Pynch
            gives merchants the ability to safely and securely connect with
            consumers without costs
          </h4>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
